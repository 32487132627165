import React from 'react'

import volume from "../assets/home/speaker-good.png";

const MuteButton = (props: { isMuted: boolean, setIsMuted: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const Unmute = () => {
        props.setIsMuted(false);
        localStorage.setItem("isMuted", JSON.stringify(false));
    }

    return (
        <div>
            {props.isMuted &&
                <button className=" z-[100] absolute top-[6%] right-[1%] w-[5%] h-[5%]rounded-full hover:brightness-150 transform hover:scale-110 transition-transform" onClick={Unmute}>
                    <img src={volume} className="" />
                </button>
            }
        </div>
    )
}

export default MuteButton