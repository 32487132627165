import React from 'react'
import { useNavigate } from 'react-router-dom';

import bgVideo from "../assets/intro.mp4";

const Intro = () => {
    const navigate = useNavigate();

    return (
        <div>
            <video className="video2 hover:cursor-pointer" id="bgVideo" onClick={() => { navigate("/home"); }} autoPlay loop muted>
                <source src={bgVideo} type="video/mp4"></source>
            </video>
        </div>
    )
}

export default Intro;