import React from 'react'
import PulseLoader from "react-spinners/PulseLoader";

const Spinner = (props: { fullscreen?: boolean }) => {
    return (
        <div className={`${props.fullscreen ? 'w-screen h-screen' : 'w-full h-full'} flex items-center align-middle justify-center`}>
            <PulseLoader
                color="#5e5f69"
                loading={true}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    )
}

export default Spinner;