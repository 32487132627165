import React, { useEffect, useState } from "react";
import "../App.css";

import { Howl, Howler } from "howler";
import ImageMapper, { AreaEvent, CustomArea, Map } from 'react-img-mapper';
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";


import bgVideo from "../assets/home/home.mp4";
import sfx_lightSwitch from "../assets/home/lightflicker.mp3";
import poster from "../assets/home/poster.png";
import transparent from "../assets/home/transparent.png";
import MuteButton from "../components/MuteButton";
import VideoBackground from "../components/VideoBackground";
import Spinner from "../components/Spinner";

import closeBtn from "../assets/closeBtn.webp"

// 720 : https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/HD_transparent_picture.png/1280px-HD_transparent_picture.png
// 1080: https://upload.wikimedia.org/wikipedia/commons/8/89/HD_transparent_picture.png


// window: style={{ ...getTipPosition(hoveredArea), boxShadow: `0 0 ${hoveredWidth + 200}px ${hoveredHeight}px #fffb88, 0 0 ${getScreenWidthRatio() * 90}px ${getScreenWidthRatio() * 60}px #fecd0b, 0 0 ${getScreenWidthRatio() * 170}px ${getScreenWidthRatio() * 120}px #ffd620` }}>
// new window:    style={{ ...getTipPosition(hoveredArea), boxShadow: `0 0 ${hoveredWidth + 500}px ${hoveredHeight + 10}px #eaff32, 0 0 ${getScreenWidthRatio() * 90}px ${getScreenWidthRatio() * 60}px #fafe0b, 0 0 ${getScreenWidthRatio() * 150}px ${getScreenWidthRatio() * 80}px #fbff20` }}>
// door: style={{ ...getTipPosition(hoveredArea), boxShadow: `0 0 ${hoveredWidth + 60}px ${hoveredHeight - 30}px #ffa837` }}>
// poster:  style={{ ...getTipPosition(hoveredArea), boxShadow: `0 0 ${hoveredWidth + 100}px ${hoveredHeight}px #fffb88, 0 0 ${getScreenWidthRatio() * 50}px ${getScreenWidthRatio() * 20}px #fecd0b, 0 0 ${getScreenWidthRatio() * 120}px ${getScreenWidthRatio() * 70}px #ffd620` }}>
// garage:  style={{ ...getTipPosition(hoveredArea), boxShadow: `0 0 ${hoveredWidth + 200}px ${hoveredHeight}px #23ffcf, 0 0 ${getScreenWidthRatio() * 60}px ${getScreenWidthRatio() * 30}px #ffffff, 0 0 ${getScreenWidthRatio() * 140}px ${getScreenWidthRatio() * 90}px #ffffff` }}>
//new garage: style={{ ...getTipPosition(hoveredArea), boxShadow: `0 0 ${hoveredWidth + 60}px ${hoveredHeight - 30}px #23ffcf` }}>

const Home = () => {
    const [hoveredAreaShadowStyling, setHoveredAreaShadowStyling] = useState('');
    const [hoveredArea, setHoveredArea] = useState<CustomArea>();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isPosterLoading, setIsPosterLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const isMuted = localStorage.getItem("isMuted");
        if (isMuted) {
            setIsMuted(JSON.parse(isMuted));
        } else {
            setIsMuted(true);
        }

        /* Refresh if infinity bug and the size not correcting properly */
        setTimeout(() => {
            let mapCoordsInfinity = document
                .getElementsByTagName('map')[0]
                .areas[0]?.attributes[1]?.nodeValue?.toString()
                .includes('Infinity');

            if (mapCoordsInfinity || mapCoordsInfinity === undefined) {
                setIsMuted(true);
                localStorage.setItem("isMuted", JSON.stringify(true));
                refreshPage();
            }
        }, 1000);

        Howler.volume(1.0);

        // Handler to call on window resize (we recalculate the coords)
        const handleResize = () => {
            for (let i = 0; i < MAP.areas.length; i++) {
                for (let j = 0; j < MAP.areas[i].coords.length; j++) {
                    MAP.areas[i].coords[j] = MAP.areas[i].coords[j] * (1280 / window.innerWidth);
                }
            }
        }

        // Bind the event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window  ize
        handleResize();

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }


    const refreshPage = () => {
        navigate(0);
    }

    const MAP: Map = {
        name: "my-map",
        areas: [
            {
                id: "window",
                shape: "poly",
                coords: [650 * (4 / 6), 230 * (4 / 6), 874 * (4 / 6), 277 * (4 / 6), 869 * (4 / 6), 367 * (4 / 6), 618 * (4 / 6), 353 * (4 / 6)],
                href: "/window",
                //fillColor: "red",
                //strokeColor: "black",
            },
            {
                id: "door",
                shape: "poly",
                coords: [848 * (4 / 6), 580 * (4 / 6), 898 * (4 / 6), 589 * (4 / 6), 918 * (4 / 6), 850 * (4 / 6), 845 * (4 / 6), 857 * (4 / 6)],
                href: "/door",
                //fillColor: "red",
                //strokeColor: "black",
            },
            {
                id: "poster",
                shape: "poly",
                coords: [1019 * (4 / 6), 492 * (4 / 6), 1096 * (4 / 6), 447 * (4 / 6), 1154 * (4 / 6), 584 * (4 / 6), 1060 * (4 / 6), 647 * (4 / 6)],
                href: "/poster",
                //fillColor: "red",
                //strokeColor: "black",
            },
            {
                id: "garage",
                "shape": "poly",
                coords: [1211 * (4 / 6), 638 * (4 / 6), 1920 * (4 / 6), 461 * (4 / 6), 1920 * (4 / 6), 1080 * (4 / 6), 1207 * (4 / 6), 1080 * (4 / 6)],
                href: "/garage",
                //fillColor: "red",
                //strokeColor: "black",
            }
        ]
    }

    const enterArea = (area: CustomArea) => {
        setHoveredArea(area);
        //setHoveredWidth(getWidth(area));
        //setHoveredHeight(getHeight(area));

        playSound(sfx_lightSwitch);

        if (area.id === 'garage') {
            setHoveredAreaShadowStyling(`0 0 ${getWidth(area) + 60}px ${getHeight(area) - 30}px #23ffcf9e`);
        }
        else if (area.id === 'poster') {
            setHoveredAreaShadowStyling(`0 0 ${getWidth(area) + 100}px ${getHeight(area)}px #fffb88, 0 0 ${getScreenWidthRatio() * 50}px ${getScreenWidthRatio() * 20}px #fecd0b, 0 0 ${getScreenWidthRatio() * 120}px ${getScreenWidthRatio() * 70}px #ffd620`);
        } else if (area.id === 'door') {
            setHoveredAreaShadowStyling(`0 0 ${getWidth(area) + 60}px ${getHeight(area) - 30}px #ffa837`);
        } else if (area.id === 'window') {
            setHoveredAreaShadowStyling(`0 0 ${getWidth(area) + 200}px ${getHeight(area) + 20}px #eaff326e, 0 0 ${getScreenWidthRatio() * 90}px ${getScreenWidthRatio() * 60}px #fafe0b68, 0 0 ${getScreenWidthRatio() * 150}px ${getScreenWidthRatio() * 80}px #fbff2071`);
        }
    }

    const leaveArea = (area: CustomArea) => {
        setHoveredArea(undefined);

        Howler.stop();
    }

    const handleClick = (area: CustomArea, index: number, event: AreaEvent) => {
        if (area.id === 'garage') {
            navigate("/garage");
        }
        else if (area.id === 'poster') {
            openModal();
        }
        else if (area.id === 'door') {
            navigate("/door");
        }
        else if (area.id === 'window') {
            navigate("/window");
        }
    }

    const getTipPosition = (area: any) => {
        return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
    }

    /* The width of the screen change the video size. */
    const getScreenWidthRatio = () => {
        return (window.innerWidth / 1920 * (4 / 6));
    }

    /* The height of the screen change the video position on the y axis. */
    const getScreenHeightRatio = () => {
        return (window.innerHeight / 1080 * (4 / 6));
    }

    const getWidth = (area: CustomArea) => {
        const width = (area.coords[4] - area.coords[0]) * getScreenWidthRatio();
        return width;
    }

    const getHeight = (area: CustomArea) => {
        const height = (area.coords[5] - area.coords[1]) * getScreenWidthRatio();
        return height;
    }

    const playSound = (src: string) => {
        const sound = new Howl({
            src: src,
            rate: Math.random() + 0.8,
            volume: Math.random() + 0.5
        });
        sound.play();
    }

    return (
        <div>
            <div className="w-full h-full">
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className="PosterModal"
                    overlayClassName="PosterOverlay"
                >
                    <div className="flex flex-row h-full justify-center">
                        <div className="flex items-center align-middle justify-center w-[80%]">
                            <img src={poster} className="h-[90%]" onLoad={() => setIsPosterLoading(false)} style={!isPosterLoading ? {} : { display: 'none' }} />
                            {isPosterLoading &&
                                <Spinner fullscreen />
                            }
                        </div>
                        {/* CLOSE BUTTON */}
                        <div className="mt-[10%]">
                            <button onClick={closeModal} >
                                <div className="2xl:w-12 xl:w-12 lg:w-12 md:w-9 sm:w-7 w-6 2xl:h-12 xl:h-12 lg:h-12 md:h-9 sm:h-7 h-6 rounded-full flex items-center justify-center scale-[150%] active:scale-[150%] transform hover:scale-[175%] transition-transform">
                                    <img src={closeBtn} />
                                </div>
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>

            <video className="video2" id="bgVideo" autoPlay loop muted={isMuted}>
                <source src={bgVideo} type="video/mp4"></source>
            </video>

            <div className="videoContainer">
                <MuteButton isMuted={isMuted} setIsMuted={setIsMuted} />

                <ImageMapper
                    src={transparent}//"https://i.ytimg.com/vi/79RJLb5m3VA/maxresdefault.jpg"
                    map={MAP}
                    width={1280}
                    height={720}
                    imgWidth={1280}
                    onMouseEnter={area => enterArea(area)}
                    onMouseLeave={area => leaveArea(area)}
                    responsive={true}
                    parentWidth={window.innerWidth}
                    onClick={handleClick}

                    //Debugging
                    active={false}
                    lineWidth={0}
                    stayHighlighted={false} // dynamic stayHighlighted
                    stayMultiHighlighted={false} // dynamic stayMultiHighlighted
                />

                {
                    hoveredArea &&
                    <span className={`tooltip`}
                        style={{ ...getTipPosition(hoveredArea), boxShadow: hoveredAreaShadowStyling }}>
                    </span>
                }
            </div>
        </div>
    );
}

export default Home;