import React, { Suspense } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Intro from "./views/Intro";
import Home from "./views/Home";
import Spinner from "./components/Spinner";

import mobileBg from "./assets/mobile.gif";

const Garage = React.lazy(() => import("./views/garage/Garage"));
const Garage_Gallery1 = React.lazy(() => import("./views/garage/galleries/Garage_Gallery1"));
const Garage_Gallery2 = React.lazy(() => import("./views/garage/galleries/Garage_Gallery2"));
const Garage_Gallery3 = React.lazy(() => import("./views/garage/galleries/Garage_Gallery3"));

const Door = React.lazy(() => import("./views/door/Door"));
const Door_Gallery1 = React.lazy(() => import("./views/door/galleries/Door_Gallery1"));
const Door_Gallery2 = React.lazy(() => import("./views/door/galleries/Door_Gallery2"));
const Door_Gallery3 = React.lazy(() => import("./views/door/galleries/Door_Gallery3"));
const Door_Gallery4 = React.lazy(() => import("./views/door/galleries/Door_Gallery4"));
const Door_Gallery5 = React.lazy(() => import("./views/door/galleries/Door_Gallery5"));
const Door_Gallery6 = React.lazy(() => import("./views/door/galleries/Door_Gallery6"));
const Door_Gallery7 = React.lazy(() => import("./views/door/galleries/Door_Gallery7"));

const Window = React.lazy(() => import("./views/window/Window"));
const Window_Gallery1 = React.lazy(() => import("./views/window/galleries/Window_Gallery1"));
const Window_Gallery2 = React.lazy(() => import("./views/window/galleries/Window_Gallery2"));
const Window_Gallery3 = React.lazy(() => import("./views/window/galleries/Window_Gallery3"));
const Window_Gallery4 = React.lazy(() => import("./views/window/galleries/Window_Gallery4"));
const Window_Gallery5 = React.lazy(() => import("./views/window/galleries/Window_Gallery5"));
const Window_Gallery6 = React.lazy(() => import("./views/window/galleries/Window_Gallery6"));
const Window_Gallery7 = React.lazy(() => import("./views/window/galleries/Window_Gallery7"));

/*
ffmpeg -i my-video.mov -vcodec h264 -acodec mp2 my-video.mp4

old nameservers:
ns1.dns-parking.com

ns2.dns-parking.com


video wont play on safari (safari on macbook)
close btn
*/


function App() {
  /*
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    }

    // Bind the event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window  ize
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);
*/
  return (
    <div className="w-max-screen h-max-screen">
      {isMobile
        ? <div className="h-screen w-screen">
          <img src={mobileBg} className='h-screen w-screen object-fill' />
          <div className="absolute top-0 left-0 z-99 w-full h-full flex flex-col items-center justify-center align-middle text-center">
            <p className="text-white text-center font-bold text-xl break-words mx-10">
              S'il vous plaît, consulter la page sur ordinateur.
            </p>
            <p className="text-white text-center font-bold mt-32 text-xl break-words mx-10">
              Please consult the website on a computer.
            </p>
          </div>
        </div>
        : <Suspense fallback={<Spinner fullscreen />}>
          <BrowserRouter>
            <Routes>
              <Route index element={<Intro />} />
              <Route path="/home" element={<Home />} />

              <Route path="/garage" element={<Garage />} />
              <Route path="/garage/gallery1" element={<Garage_Gallery1 />} />
              <Route path="/garage/gallery2" element={<Garage_Gallery2 />} />
              <Route path="/garage/gallery3" element={<Garage_Gallery3 />} />

              <Route path="/door" element={<Door />} />
              <Route path="/door/gallery1" element={<Door_Gallery1 />} />
              <Route path="/door/gallery2" element={<Door_Gallery2 />} />
              <Route path="/door/gallery3" element={<Door_Gallery3 />} />
              <Route path="/door/gallery4" element={<Door_Gallery4 />} />
              <Route path="/door/gallery5" element={<Door_Gallery5 />} />
              <Route path="/door/gallery6" element={<Door_Gallery6 />} />
              <Route path="/door/gallery7" element={<Door_Gallery7 />} />

              <Route path="/window" element={<Window />} />
              <Route path="/window/gallery1" element={<Window_Gallery1 />} />
              <Route path="/window/gallery2" element={<Window_Gallery2 />} />
              <Route path="/window/gallery3" element={<Window_Gallery3 />} />
              <Route path="/window/gallery4" element={<Window_Gallery4 />} />
              <Route path="/window/gallery5" element={<Window_Gallery5 />} />
              <Route path="/window/gallery6" element={<Window_Gallery6 />} />
              <Route path="/window/gallery7" element={<Window_Gallery7 />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      }
    </div>
  )
}

export default App;

